S
<template>
	<UCard
		:ui="{
			base: 'flex flex-col justify-center items-center ',
			background: 'bg-white dark:bg-gray-900',
			divide: 'divide-y-0',
			ring: 'ring-0',
			rounded: 'rounded-none',
			shadow: 'shadow-none',
			body: {
				base: '',
				background: '',
				padding: 'px-4 py-2 sm:p-0',
			},
			header: {
				base: '',
				background: '',
				padding: 'px-0 py-2 sm:px-0',
			},
			footer: {
				base: '',
				background: '',
				padding: 'px-0 py-0 pt-4 pb-2 sm:px-0',
			},
		}"
	>
		<template #header>
			<img src="../../assets/img/exclamation.png" />
		</template>

		<div class="flex-col-center wrapper">
			<h1 :class="props.titleClass">{{ props.title }}</h1>
			<h2 v-if="props.description" :class="props.descriptionClass">{{ props.description }}</h2>
		</div>

		<template #footer>
			<ButtonImage :title="props.buttonTitle" title-class="text-xl h-7" :classes="props.buttonTitleClass" @click="$emit('click')" />
		</template>
	</UCard>
</template>

<script lang="ts" setup>
defineEmits(['click']);

const props = defineProps<{
	title: string;
	titleClass?: string;
	description?: string;
	descriptionClass?: string;
	buttonTitle: string;
	buttonTitleClass?: string;
}>();
</script>

<style scoped>
img {
	@apply w-[56px] h-[56px];
}

.wrapper {
	@apply py-2 font-jakarta;
}

h1 {
	@apply font-semibold text-neutral-80 text-center;
}

h2 {
	@apply mt-2 text-neutral-50 text-center;
}
</style>
