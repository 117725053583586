import { defineStore } from 'pinia';
import type { NearbyShopReq } from '../repository/types/request/nearby-shop.request';
import type { IApiInstance } from '../types/plugins';
import type { Shop } from '../types';

export const useShopStore = defineStore({
	id: 'shopStore',
	state: () => ({
		loading: false as boolean,
		nearbyShops: [] as Shop[],
		selectedShop: {} as Shop,
		stateId: undefined as number | undefined,
	}),
	getters: {
		showMoreMerchantsButton(): boolean {
			return this.nearbyShops.length % 6 === 0;
		},
		getStateName(): string {
			let stateName = '';
			const utilsState = useUtilsDataStore();
			const { states } = storeToRefs(utilsState);

			if (this.stateId == null) return 'All States';

			const state = states.value.find((state) => state.id === this.stateId);
			if (state != null) {
				stateName = state.name;
			}
			return stateName;
		},
	},
	actions: {
		async updateStateId(id: number | undefined) {
			this.stateId = id;
			await this.getNearbyShops(0);
		},

		async getNearbyShops(skip: number = 0) {
			const userStore = useUserStore();

			this.loading = true;

			try {
				const shopReq: NearbyShopReq = {
					skip: skip.toString(),
					take: '6'.toString(),
					lat: userStore.location?.latitude.toString() ?? '0',
					lng: userStore.location?.longitude.toString() ?? '0',
				};

				if (this.stateId) {
					shopReq['state_id'] = this.stateId.toString();
				}

				const $api = useNuxtApp().$api as IApiInstance;
				const response = await $api.shop.getNearbyShops(shopReq);

				if (skip == 0) {
					this.nearbyShops = response || [];
				} else {
					this.nearbyShops.push(...(response || []));
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},

		async getShopbyId() {
			// const userStore = useUserStore();

			this.loading = true;

			try {
				// const shopReq: NearbyShopReq = {
				// 	skip: skip.toString(),
				// 	take: '6'.toString(),
				// 	lat: userStore.location?.latitude.toString() ?? '0',
				// 	lng: userStore.location?.longitude.toString() ?? '0',
				// };
				// if (this.stateId) {
				// 	shopReq['state_id'] = this.stateId.toString();
				// }
				// const $api = useNuxtApp().$api as IApiInstance;
				// const response = await $api.shop.getNearbyShops(shopReq);
				// if (skip == 0) {
				// 	this.nearbyShops = response || [];
				// } else {
				// 	this.nearbyShops.push(...(response || []));
				// }
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},

		async setSelectedShop(shop: Shop) {
			this.selectedShop = shop;
		},
	},
});
