<template>
	<div class="flex flex-col">
		<div class="shadow">
			<WemotooHeader />
		</div>
		<div class="w-full bg-white">
			<StatusError :title="message" button-title="Back Home" @click="toHome()" />
		</div>
		<WemotooFooter />
	</div>
</template>

<script lang="ts" setup>
const firebase = useFirebaseStore();
const error = useError();

firebase.logCustomEvent(ERROR_VIEW, {
	statusCode: error.value?.statusCode,
	statusMessage: error.value?.statusMessage,
});

const message = computed(() => {
	if (error.value?.statusMessage) {
		return error.value?.statusMessage;
	}
	return 'Something went wrong';
});

useSeoMeta({
	title: () => `Wemotoo`,
});

const toHome = async () => {
	clearError({ redirect: '/' });
};
</script>

<style scoped>
.shadow {
	position: sticky;
	top: 0;
	z-index: 1000;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>
