import {
	AuthModule,
	StateModule,
	CountryModule,
	EventModule,
	ArticleModule,
	OrderModule,
	ShopModule,
	UserModule,
	VehicleModule,
	VoucherModule,
} from '../repository/modules';
import type { IApiInstance } from '../types/plugins';

export default defineNuxtPlugin((_nuxtApp) => {
	// const config = useRuntimeConfig();
	// const BASE_URL: string = config.public.BASE_URL;

	const apiFetcher = $fetch.create({
		// onRequest({ request, response }) {
		// 	console.log('Request:', request);
		// 	console.log('Response:', response);
		// },
	});

	const authModule = new AuthModule(apiFetcher);
	const countryModule = new CountryModule(apiFetcher);
	const eventModule = new EventModule(apiFetcher);
	const articleModule = new ArticleModule(apiFetcher);
	const orderModule = new OrderModule(apiFetcher);
	const shopModule = new ShopModule(apiFetcher);
	const stateModule = new StateModule(apiFetcher);
	const userModule = new UserModule(apiFetcher);
	const vehicleModule = new VehicleModule(apiFetcher);
	const voucherModule = new VoucherModule(apiFetcher);

	const modules: IApiInstance = {
		auth: authModule,
		country: countryModule,
		event: eventModule,
		article: articleModule,
		order: orderModule,
		shop: shopModule,
		state: stateModule,
		user: userModule,
		vehicle: vehicleModule,
		voucher: voucherModule,
	};
	/*
	 * ! This code below is example for the case you want to authenticate user and set token to headers.
	 * Why call api in process.client?
	 * => Cookie only exist on client.
	 *  */
	if (import.meta.client) {
		// modules.auth
		// 	.login(email, password)
		// 	.then((data) => {
		// 		if (data.accessToken) {
		// 			productTagModule.setAccessToken(data.accessToken);
		// 		}
		// 	})
		// 	.catch((err: any) => {
		// 		console.error('Error occur:' + err);
		// 	});
	}

	return {
		provide: {
			api: modules,
		},
	};
});
