import type { UpdateUserReq } from '../types/request';
import HttpFactory from '../factory';
import Routes from '../routes.client';
import type { User } from '../../types';

export default class UserModule extends HttpFactory {
	private readonly RESOURCE = Routes.User;

	/**
	 * get user profile
	 * @returns user profile
	 */
	async getUserProfile(): Promise<User> {
		const response = await this.call<User>(
			{
				method: 'GET',
				url: `${this.RESOURCE.GetUserProfile()}`,
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<User>(response);
	}

	/**
	 * update user profile
	 * @returns user profile
	 */
	async updateUserProfile(data: UpdateUserReq): Promise<User> {
		const response = await this.call<User>(
			{
				method: 'POST',
				url: `${this.RESOURCE.UpdateUserProfile()}`,
				body: data,
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<User>(response);
	}
}
