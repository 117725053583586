import { defineStore } from 'pinia';
import type { IApiInstance } from '../types/plugins';
import type { Country, State } from '../types';

export const useUtilsDataStore = defineStore({
	id: 'utilsDataStore',
	state: () => {
		return {
			loading: false as boolean,
			countries: [] as Country[],
			states: [] as State[],
			initialized: false as boolean,
		};
	},
	getters: {
		getCountries(): Country[] {
			return this.countries;
		},
		getStateList(): State[] {
			return this.states;
		},
	},
	actions: {
		async fetchCountryList() {
			try {
				this.loading = true;

				const $api = useNuxtApp().$api as IApiInstance;
				const apiResult = await $api.country.getCountries();

				if (apiResult != null && apiResult.length > 0) {
					this.countries = [...apiResult!];
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},

		async fetchStateList() {
			try {
				this.loading = true;

				const $api = useNuxtApp().$api as IApiInstance;
				const apiResult = await $api.state.getStates();

				if (apiResult != null && apiResult.length > 0) {
					this.states = [...apiResult!];
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},

		async setInitialized() {
			this.initialized = true;
		},
	},
});
