import type { $Fetch, NitroFetchOptions } from 'nitropack';
import Constants from '../utils/constants';

type AsyncData<T> = {
	data: T;
	pending: Ref<boolean>;
	refresh: (opts?: AsyncDataExecuteOptions) => Promise<void>;
	execute: (opts?: AsyncDataExecuteOptions) => Promise<void>;
	clear: () => void;
	error: Ref<any | null>;
	status: Ref<AsyncDataRequestStatus>;
};

interface AsyncDataExecuteOptions {
	dedupe?: 'cancel' | 'defer';
}

type AsyncDataRequestStatus = 'idle' | 'pending' | 'success' | 'error';

interface IHttpFactory {
	method:
		| 'GET'
		| 'HEAD'
		| 'PATCH'
		| 'POST'
		| 'PUT'
		| 'DELETE'
		| 'CONNECT'
		| 'OPTIONS'
		| 'TRACE'
		| 'get'
		| 'head'
		| 'patch'
		| 'post'
		| 'put'
		| 'delete'
		| 'connect'
		| 'options'
		| 'trace';
	url: string;
	fetchOptions?: NitroFetchOptions<'json'>;
	body?: object;
	headers?: Record<string, string>;
}

class HttpFactory {
	private readonly $fetch: $Fetch;
	static token: string = '';

	constructor(fetch: $Fetch) {
		this.$fetch = fetch;
	}

	async call<T>(
		{
			method,
			url,
			fetchOptions,
			body,
			headers = {
				'Content-Type': 'application/json',
			},
		}: IHttpFactory,
		errorHandler?: (error: any) => Promise<void>,
	) {
		try {
			const response = await this.$fetch<AsyncData<T>>(url, {
				method,
				body,
				headers,
				...fetchOptions,
			});

			if (response.status.value == 'error') {
				throw new Error(response.error.value);
			}

			return response.data;
		} catch (error: any) {
			console.log(error);
			if (errorHandler) {
				await errorHandler(error);
			} else {
				throw new Error(error.data?.data.message);
			}
		}
	}

	validateResponse<T>(response: any): T {
		if (response) {
			return response;
		} else {
			throw new Error('Failed to fetch');
		}
	}

	setToken(token: string) {
		HttpFactory.token = token;
		localStorage.setItem(Constants.ACCESS_TOKEN, HttpFactory.token);
	}
}
export default HttpFactory;
