import type { NearbyShopReq } from '../types/request';
import HttpFactory from '../factory';
import Routes from '../routes.client';
import type { Shop } from '../../types';

export default class ShopModule extends HttpFactory {
	private readonly RESOURCE = Routes.Shop;

	/**
	 * get nearby shops
	 * @returns list of Shop
	 */
	async getNearbyShops(data: NearbyShopReq): Promise<Shop[]> {
		const response = await this.call<Shop[]>(
			{
				method: 'POST',
				url: `${this.RESOURCE.GetNearbyShops()}`,
				body: data,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<Shop[]>(response);
	}
}
