import type { ApplyVoucherReq } from '../types/request';
import type { ApplyVoucherResp } from '../types/response';
import HttpFactory from '../factory';
import Routes from '../routes.client';

export default class VoucherModule extends HttpFactory {
	private readonly RESOURCE = Routes.Voucher;

	/**
	 * apply voucher
	 * @returns ApplyVoucherResp
	 */
	async applyVoucher(data: ApplyVoucherReq): Promise<ApplyVoucherResp> {
		const response = await this.call<ApplyVoucherResp>(
			{
				method: 'POST',
				url: `${this.RESOURCE.ApplyVoucher()}`,
				body: data,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<ApplyVoucherResp>(response);
	}
}
