export default defineAppConfig({
	ui: {
		primary: 'wemotooPrimary',

		//* Slide over *//
		slideover: {
			wrapper: 'fixed inset-0 flex z-50',
			overlay: {
				base: 'fixed inset-0 transition-opacity',
				background: 'bg-gray-200/75 dark:bg-gray-800/75',
				transition: {
					enter: 'ease-in-out duration-500',
					enterFrom: 'opacity-0',
					enterTo: 'opacity-100',
					leave: 'ease-in-out duration-500',
					leaveFrom: 'opacity-100',
					leaveTo: 'opacity-0',
				},
			},
			base: 'relative flex-1 flex flex-col w-full focus:outline-none',
			background: 'bg-white dark:bg-gray-900',
			ring: '',
			rounded: '',
			padding: '',
			shadow: 'shadow-xl',
			width: 'w-screen max-w-[80%]',
			height: 'h-screen max-h-96',
			translate: {
				base: 'translate-x-0',
				left: '-translate-x-full rtl:translate-x-full',
				right: 'translate-x-full rtl:-translate-x-full',
				top: '-translate-y-full',
				bottom: 'translate-y-full',
			},
			transition: {
				enter: 'transform transition ease-in-out duration-300',
				leave: 'transform transition ease-in-out duration-200',
			},
		},

		//* Modal *//
		modal: {
			container: 'flex min-h-full items-center sm:items-center justify-center text-center',
			overlay: {
				base: 'fixed inset-0 transition-opacity',
				background: 'bg-neutral-90 opacity-70',
				transition: {
					enter: 'ease-out duration-300',
					enterFrom: 'opacity-0',
					enterTo: 'opacity-100',
					leave: 'ease-in duration-200',
					leaveFrom: 'opacity-100',
					leaveTo: 'opacity-0',
				},
			},
		},

		//* Tabs *//
		tabs: {
			list: {
				base: 'relative mt-5',
				background: 'bg-transparent',
				rounded: 'rounded-lg',
				shadow: '',
				padding: 'p-0',
				height: 'h-10',
				width: 'w-full',
				marker: {
					wrapper: 'absolute top-[4px] left-[4px] duration-200 ease-out focus:outline-none',
					base: 'w-full h-full',
					background: 'bg-transparent',
					rounded: 'rounded-none',
					shadow: 'shadow-none',
				},
				tab: {
					base: 'relative inline-flex items-center justify-center flex-shrink-0 w-full ui-focus-visible:outline-0 ui-focus-visible:ring-2 ui-focus-visible:ring-primary-500 ui-not-focus-visible:outline-none focus:outline-none disabled:cursor-not-allowed disabled:opacity-75 transition-colors duration-200 ease-out font-jakarta',
					background: 'bg-transparent',
					active: 'text-wemotooPrimary border-b-2 border-wemotooPrimary',
					inactive: 'text-wemotooGrey',
					height: 'h-8',
					padding: 'px-3',
					size: 'text-[14px]',
					font: 'font-regular',
					rounded: 'rounded-none',
					shadow: 'shadow-none',
				},
			},
		},
		// //* Notification *//
		// notifications: {
		// 	// Show toasts at the top right of the screen
		// 	position: 'top-0 right-0 bottom-auto left-auto',
		// },

		// notification: {
		// 	default: {
		// 		closeButton: {
		// 			icon: 'i-octicon-x-24',
		// 		},
		// 	},
		// },

		// //* Card *//
		// card: {
		// 	base: 'transition duration-200 py-2',
		// 	background: 'bg-white',
		// 	shadow: 'shadow-md',
		// 	divide: 'divide-none',
		// 	rounded: `rounded`,
		// 	header: {
		// 		base: '',
		// 		background: '',
		// 		padding: 'px-4 py-2',
		// 	},
		// 	body: {
		// 		base: '',
		// 		background: '',
		// 		padding: 'px-4 py-3',
		// 	},
		// 	footer: {
		// 		base: '',
		// 		background: '',
		// 		padding: 'px-4 pt-2 pb-2',
		// 	},
		// },

		// //* Button *//
		// button: {
		// 	base: 'transition duration-200',
		// 	font: 'font-bold font-nunito',
		// 	rounded: 'rounded',
		// 	default: {
		// 		loadingIcon: 'i-material-symbols-sync-rounded',
		// 	},
		// 	size: {
		// 		'2xs': 'text-xs md:text-xs',
		// 		'xs': 'text-xs md:text-xs',
		// 		'sm': 'text-sm md:text-sm',
		// 		'md': 'text-sm md:text-base',
		// 		'lg': 'text-sm md:text-xl',
		// 		'xl': 'text-sm md:text-2xl',
		// 	},
		// 	color: {
		// 		primary: {
		// 			solid: 'button-primary-solid disabled:button-primary-solid-disabled',
		// 			outline: 'button-primary-outline disabled:button-primary-outline-disabled',
		// 		},
		// 		green: {
		// 			solid: 'button-green-solid',
		// 			outline: 'button-green-outline disabled:button-green-outline-disabled',
		// 		},
		// 	},
		// 	padding: {
		// 		'2xs': 'px-2 py-1',
		// 		'xs': 'px-2.5 py-1.5',
		// 		'sm': 'px-2.5 py-1.5',
		// 		'md': 'px-3 py-2',
		// 		'lg': 'px-5 py-3',
		// 		'xl': 'px-10 py-3',
		// 	},
		// },

		// //* Badge *//
		// badge: {
		// 	rounded: 'rounded',
		// },

		// checkbox: {
		// 	container: 'flex items-center h-6',
		// 	base: 'h-5 w-5 disabled:opacity-50 disabled:cursor-not-allowed focus:ring-0 focus:ring-transparent focus:ring-offset-transparent',
		// 	label: 'text-md font-medium text-gray-700',
		// 	required: 'text-md text-red-500 ',
		// 	help: 'text-md text-gray-500 ',
		// },

		// // * FormGroup *//
		// formGroup: {
		// 	wrapper: '',
		// 	inner: '',
		// 	label: {
		// 		wrapper: 'flex content-center items-center justify-between',
		// 		base: 'block font-normal text-secondary-700',
		// 		required: "after:content-['*'] after:ms-0.5 after:text-red-500",
		// 	},
		// 	size: {
		// 		'2xs': 'text-xs md:text-xs',
		// 		'xs': 'text-xs md:text-xs',
		// 		'sm': 'text-sm md:text-sm',
		// 		'md': 'text-sm md:text-base',
		// 		'lg': 'text-sm md:text-xl',
		// 		'xl': 'text-sm md:text-2xl',
		// 	},
		// 	container: 'mt-1 relative',
		// 	description: 'text-gray-500',
		// 	hint: 'text-gray-500',
		// 	help: 'mt-2 text-gray-500',
		// 	error: 'mt-2 text-red-500',
		// 	default: {
		// 		size: 'sm',
		// 	},
		// },

		// //* Input *//
		// input: {
		// 	size: {
		// 		'2xs': 'text-xs md:text-xs',
		// 		'xs': 'text-xs md:text-xs',
		// 		'sm': 'text-sm md:text-sm',
		// 		'md': 'text-sm md:text-base',
		// 		'lg': 'text-sm md:text-xl',
		// 		'xl': 'text-sm md:text-2xl',
		// 	},
		// 	padding: {
		// 		'2xs': 'px-2 py-1',
		// 		'xs': 'px-2 py-1',
		// 		'sm': 'px-2 py-1',
		// 		'md': 'px-4 py-2',
		// 		'lg': 'px-4 py-2',
		// 		'xl': 'px-4 py-3',
		// 	},
		// 	leading: {
		// 		padding: {
		// 			'2xs': 'ps-8',
		// 			'xs': 'ps-9',
		// 			'sm': 'ps-10',
		// 			'md': 'ps-11',
		// 			'lg': 'ps-12',
		// 			'xl': 'ps-13',
		// 		},
		// 	},
		// 	trailing: {
		// 		padding: {
		// 			'2xs': 'pe-8',
		// 			'xs': 'pe-9',
		// 			'sm': 'pe-10',
		// 			'md': 'pe-11',
		// 			'lg': 'pe-12',
		// 			'xl': 'pe-13',
		// 		},
		// 	},
		// 	default: {
		// 		size: 'md',
		// 		loadingIcon: 'i-material-symbols-sync-rounded',
		// 	},
		// 	rounded: `rounded`,
		// },

		// //* Textarea *//
		// textarea: {
		// 	rounded: 'rounded',
		// },

		// //* Select *//
		// select: {
		// 	rounded: 'rounded',
		// 	size: {
		// 		'2xs': 'text-xs md:text-xs',
		// 		'xs': 'text-xs md:text-xs',
		// 		'sm': 'text-sm md:text-sm',
		// 		'md': 'text-sm md:text-base',
		// 		'lg': 'text-sm md:text-xl',
		// 		'xl': 'text-sm md:text-2xl',
		// 	},
		// 	padding: {
		// 		'2xs': 'px-2 py-1',
		// 		'xs': 'px-2 py-1',
		// 		'sm': 'px-2 py-1',
		// 		'md': 'px-4 py-2',
		// 		'lg': 'px-4 py-2',
		// 		'xl': 'px-4 py-3',
		// 	},
		// 	default: {
		// 		loadingIcon: 'i-material-symbols-sync-rounded',
		// 		trailingIcon: 'i-material-symbols-expand-more-rounded',
		// 	},
		// },

		// //* Select Menu *//
		// selectMenu: {
		// 	rounded: 'rounded',
		// 	padding: 'px-4 py-2',
		// 	default: {
		// 		selectedIcon: 'i-material-symbols-fitbit-check-small-rounded',
		// 	},
		// },

		// //* Command Palette *//
		// commandPalette: {
		// 	default: {
		// 		icon: 'i-material-symbols-search-rounded',
		// 		loadingIcon: 'i-material-symbols-sync-rounded',
		// 		selectedIcon: 'i-material-symbols-fitbit-check-small-rounded',
		// 		emptyState: {
		// 			icon: 'i-material-symbols-search-rounded',
		// 		},
		// 	},
		// },

		// //* Table *//
		// table: {
		// 	default: {
		// 		sortAscIcon: 'octicon:sort-asc-24',
		// 		sortDescIcon: 'octicon:sort-desc-24',
		// 		// sortButton: {
		// 		// 	icon: 'octicon-arrow-switch-24',
		// 		// },
		// 		loadingState: {
		// 			icon: 'i-material-symbols-sync-rounded',
		// 		},
		// 		emptyState: {
		// 			icon: 'i-material-symbols-database-outline',
		// 		},
		// 	},
		// },

		// //* Avatar *//
		// avatar: {
		// 	default: {},
		// 	rounded: 'rounded',
		// },

		// //* Breadcrumb *//
		// breadcrumb: {
		// 	li: 'flex items-center gap-x-1.5 text-secondary-200  text-sm leading-6 min-w-0',
		// 	base: 'flex items-center gap-x-1.5 group font-light min-w-0',
		// 	active: 'text-secondary-800 font-medium',
		// 	inactive: 'hover:text-secondary-500',
		// 	default: {
		// 		divider: 'i-material-symbols-light-chevron-right',
		// 	},
		// },

		// //* Pagination *//
		// pagination: {
		// 	rounded: '',
		// 	default: {
		// 		prevButton: {
		// 			icon: 'i-material-symbols-arrow-back-rounded',
		// 		},
		// 		nextButton: {
		// 			icon: 'i-material-symbols-arrow-forward-rounded',
		// 		},
		// 	},
		// },
	},
});
