import type { LoginReq, OtpReq, RegisterReq } from '../types/request';
import type { LoginResp, RegisterResp } from '../types/response';
import HttpFactory from '../factory';
import Routes from '../routes.client';

export default class AuthModule extends HttpFactory {
	private readonly RESOURCE = Routes.Auth;

	/**
	 * register an user account
	 * @param RegisterBody
	 * @returns RegisterResp
	 */
	async register(data: RegisterReq): Promise<RegisterResp> {
		const response = await this.call<RegisterResp>(
			{
				method: 'POST',
				url: `${this.RESOURCE.Register()}`,
				body: data,
			},
			(error) => {
				throw error.data?.data.code + ' : ' + error.data?.data.message;
			},
		);

		const resp = this.validateResponse<RegisterResp>(response);

		this.setToken(resp.access_token);

		return resp;
	}

	/**
	 * login user account
	 * @param LoginReq
	 * @returns LoginResp
	 */
	async login(data: LoginReq): Promise<LoginResp> {
		const response = await this.call<LoginResp>(
			{
				method: 'POST',
				url: `${this.RESOURCE.Login()}`,
				body: data,
			},
			(error) => {
				throw error.data?.data.code + ' : ' + error.data?.data.message;
			},
		);

		const resp = this.validateResponse<LoginResp>(response);
		this.setToken(resp.access_token);

		return resp;
	}

	/**
	 * social login user account
	 * @param email && @param password
	 * @returns
	 */
	// async socialLogin(data: LoginReq): Promise<LoginResp> {
	// 	return await this.call<any>({
	// 		method: 'POST',
	// 		url: `${this.RESOURCE.Login()}`,
	// 		body: data,
	// 	});
	// }

	/**
	 * reset user password
	 * @param email && @param password
	 * @returns
	 */
	// async resetPassword(data: LoginReq): Promise<LoginResp> {
	// 	return await this.call<any>({
	// 		method: 'POST',
	// 		url: `${this.RESOURCE.ResetPassword()}`,
	// 		body: data,
	// 	});
	// }

	/**
	 * change user password
	 * @param email && @param password
	 * @returns
	 */
	// async changePassword(data: LoginReq): Promise<LoginResp> {
	// 	return await this.call<any>({
	// 		method: 'POST',
	// 		url: `${this.RESOURCE.Login()}`,
	// 		body: data,
	// 	});
	// }

	/**
	 * request otp
	 * @param OtpReq
	 * @returns
	 */
	async requestOTP(data: OtpReq) {
		return await this.call(
			{
				method: 'POST',
				url: `${this.RESOURCE.RequestOTP()}`,
				body: data,
			},
			(error) => {
				throw error.data?.data.code + ' : ' + error.data?.data.message;
			},
		);
	}

	/**
	 * login with email and password
	 * @param email && @param password
	 * @returns
	 */
	// async logout(email: string, password: string) {
	// 	return await this.call<any>({
	// 		method: 'GET',
	// 		url: `${this.RESOURCE.Login()}`,
	// 	});
	// }
}
