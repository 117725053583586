import type { CreateVehicleReq, VehicleListingReq } from '../types/request';
import type { VehicleModelResp, VehicleResp } from '../types/response';
import HttpFactory from '../factory';
import Routes from '../routes.client';

export default class VehicleModule extends HttpFactory {
	private readonly RESOURCE = Routes.Vehicle;

	/**
	 * Create vehicle detail
	 * @params CreateVehicleReq
	 * @returns VehicleResp
	 */
	async createVehicle(data: CreateVehicleReq): Promise<VehicleResp> {
		const formData = new FormData();

		const imageFile = data.image.value;
		formData.append('image', imageFile, imageFile.name);

		formData.append('insurance_company_name', data.insurance_company_name);
		formData.append('vehicle_model_id', data.vehicle_model_id ?? '');
		formData.append('number_plate', data.number_plate);
		formData.append('road_tax_expired_date', data.road_tax_expired_date);
		formData.append('purchase_date', data.purchase_date);
		formData.append('is_default', String(data.is_default));
		formData.append('loan_payment_start_date', data.loan_payment_start_date);
		formData.append('vehicle_type', String(data.vehicle_type));
		formData.append('repayment_years', String(data.repayment_years));
		formData.append('vehicle_model_name', data.vehicle_model_name ?? '');

		const response = await this.call<VehicleResp>(
			{
				method: 'POST',
				url: `${this.RESOURCE.CreateVehicle()}`,
				body: formData,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<VehicleResp>(response);
	}

	/**
	 * get default vehicle
	 * @returns VehicleResp
	 */
	async getDefaultVehicle(): Promise<VehicleResp> {
		const response = await this.call<VehicleResp>(
			{
				method: 'GET',
				url: `${this.RESOURCE.DefaultVehicle()}`,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<VehicleResp>(response);
	}

	/**
	 * Update vehicle detail
	 * @params CreateVehicleReq
	 * @returns VehicleResp
	 */
	async updateVehicle(data: CreateVehicleReq): Promise<VehicleResp> {
		const response = await this.call<VehicleResp>(
			{
				method: 'POST',
				url: `${this.RESOURCE.UpdateVehicle()}`,
				body: data,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<VehicleResp>(response);
	}

	/**
	 * @params VehicleListingReq
	 * @returns list of vehicle
	 */
	async getVehicles(data: VehicleListingReq): Promise<VehicleResp[]> {
		const response = await this.call<VehicleResp[]>(
			{
				method: 'POST',
				url: `${this.RESOURCE.GetVehicles()}`,
				body: data,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<VehicleResp[]>(response);
	}

	/**
	 * @params VehicleListingReq
	 * @returns list of vehicle
	 */
	async getVehicleModels(data: VehicleListingReq): Promise<VehicleModelResp[]> {
		const response = await this.call<VehicleModelResp[]>(
			{
				method: 'POST',
				url: `${this.RESOURCE.GetVehicleModels()}`,
				body: data,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<VehicleModelResp[]>(response);
	}
}
