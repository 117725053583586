import type { LatLong } from '../types';

export default () => {
	const location = ref<LatLong | null>(null);
	async function getLocation(): Promise<LatLong | null | undefined> {
		try {
			if (navigator.permissions) {
				const permission = await navigator.permissions.query({ name: 'geolocation' });
				if (permission.state === 'denied') {
					console.error('Geolocation permission denied.');
					// toast.add({
					//     title: "Geolocation Error",
					//     description: "Geolocation permission denied.",
					// });
					return location.value;
				}
			}
			if (navigator.geolocation) {
				return await new Promise((resolve, reject) => {
					navigator.geolocation.getCurrentPosition(
						(position) => {
							location.value = {
								latitude: position.coords.latitude,
								longitude: position.coords.longitude,
							};
							resolve(location.value);
						},
						(error) => {
							console.error(error);
							// toast.add({
							// 	title: "Geolocation Error",
							// 	description: error.toString(),
							// });
							reject(error);
						},
					);
				});
			} else {
				// toast.add({
				// 	title: "Geolocation Error",
				// 	description: "Geolocation is not supported by this browser.",
				// });
				console.error('Geolocation is not supported by this browser.');
				return location.value;
			}
		} catch (error) {
			console.error('An error occurred while getting the location:', error);
			return location.value;
		}
	}

	return getLocation;
};
