import { defineStore } from 'pinia';
import type { KnowledgeCategoryReq, KnowledgeListingReq, KnowledgeReq } from '../repository/types/request';
import type { IApiInstance } from '../types/plugins';
import type { KnowledgeCategory, Knowledge } from '../types';

export const useArticleStore = defineStore({
	id: 'articleStore',
	state: () => ({
		loading: false as boolean,
		categories: [] as KnowledgeCategory[],
		selectedArticle: {} as Knowledge,
	}),
	actions: {
		getRandomKnowledges(count: number = 2): Knowledge[] {
			const allArticles = this.categories.flatMap((category) => category.knowledges || []);
			const filteredArticles = allArticles.filter((knowledge) => knowledge.id !== this.selectedArticle.id);

			if (filteredArticles.length < count) {
				return filteredArticles; // Return all if less than count
			}

			const shuffled = filteredArticles.sort(() => 0.5 - Math.random());
			return shuffled.slice(0, count); // Return the first 'count' items
		},
		async getKnowledgeCategories(skip: number = 0) {
			this.loading = true;
			try {
				const knowledgeCatReq: KnowledgeCategoryReq = {
					skip: skip.toString(),
					take: '6'.toString(),
				};

				const $api = useNuxtApp().$api as IApiInstance;
				const response = await $api.article.getKnowledgeCategories(knowledgeCatReq);

				if (skip == 0) {
					this.categories = response || [];
				} else {
					this.categories.push(...(response || []));
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},
		async getKnowledgeByCategoryid(id: number, skip: number = 0) {
			this.loading = true;

			try {
				const knowledgeListingReq: KnowledgeListingReq = {
					category_id: id.toString(),
					skip: skip.toString(),
					take: '6'.toString(),
				};

				const $api = useNuxtApp().$api as IApiInstance;
				const response = await $api.article.getKnowledgeByCategoryId(knowledgeListingReq);
				const knowledge: Knowledge[] = response || [];
				const index: number = this.categories.findIndex((x) => x.id == id);

				if (skip == 0) {
					this.categories[index].knowledges = knowledge;
				} else {
					this.categories[index].knowledges.push(...knowledge);
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},
		async getKnowledgeDetailById(id: number) {
			this.loading = true;

			try {
				const $api = useNuxtApp().$api as IApiInstance;
				const knowledgeReq: KnowledgeReq = {
					id: id.toString(),
				};
				const response = await $api.article.getKnowledgeDetailById(knowledgeReq);

				this.selectedArticle = response;
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},
	},
});
