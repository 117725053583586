import { defineStore } from 'pinia';

export const useStagingStore = defineStore({
	id: 'myStagingStore',
	state: () => ({
		requiredPassword: useRuntimeConfig().public.REQUIRED_PASSWORD == '1',
		isAuthenticated: false,
	}),
	actions: {
		setAuthenticated(value: boolean) {
			this.isAuthenticated = value;
		},
	},
});
