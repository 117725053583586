/*
 * Prefix /api for api server
 */
const prefix: string = '/api';
const prefix_V2: string = '/api/v2';

/*
 * Prefix /api/another for another server
 * */
// const anotherPrefix: string = '/api/another';

const Routes = {
	Auth: {
		Login: () => `${prefix}/auth/login`,
		Register: () => `${prefix}/auth/register`,
		SocialLogin: () => `${prefix}/auth/social-login`,
		ResetPassword: () => `${prefix}/auth/reset-password`,
		ChangePassword: () => `${prefix}/auth/change-password`,
		RequestOTP: () => `${prefix}/auth/request-otp`,
		VerifyOTP: () => `${prefix}/auth/verify-otp`,
		Logout: () => `${prefix}/auth/logout`,
	},
	Event: {
		GetEvents: () => `${prefix_V2}/event/listing`,
		GetEventDetails: () => `${prefix_V2}/event/detail`,
		GetUserEvent: () => `${prefix}/event/listing`,
		GetEventTickets: () => `${prefix}/event/ticket`,
		GetPrefillDetail: () => `${prefix}/event/prefill`,
	},
	Order: {
		GetOrders: () => `${prefix}/order/listing`,
		GetOrderDetail: () => `${prefix}/order/detail`,
		Checkout: () => `${prefix}/order/checkout`,
	},
	User: {
		GetUserProfile: () => `${prefix}/user/profile`,
		GetUserSocialProfile: () => `${prefix}/user/social-profile`,
		UpdateUserProfile: () => `${prefix}/user/update`,
	},
	Country: {
		GetCountries: () => `${prefix}/country/listing`,
	},
	State: {
		GetStates: () => `${prefix}/state/listing`,
	},
	Voucher: {
		ApplyVoucher: () => `${prefix}/voucher/apply`,
	},
	Vehicle: {
		GetVehicles: () => `${prefix}/vehicle/listing`,
		GetVehicleModels: () => `${prefix}/vehicle/model-listing`,
		CreateVehicle: () => `${prefix}/vehicle/create`,
		DefaultVehicle: () => `${prefix}/vehicle/default`,
		UpdateVehicle: () => `${prefix}/vehicle/update`,
	},
	Shop: {
		GetNearbyShops: () => `${prefix_V2}/shop/listing`,
	},
	Knowledge: {
		GetKnowledgeCategories: () => `${prefix_V2}/knowledge/category-listing`,
		GetKnowledgesByCategoryId: () => `${prefix_V2}/knowledge/listing`,
		GetKnowledgeDetails: () => `${prefix_V2}/knowledge/detail`,
	},
};

export default Routes;
