import validate from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "article-detail": () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/middleware/article-detail.ts"),
  auth: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/middleware/auth.ts"),
  "clear-event-tickets": () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/middleware/clear-event-tickets.ts"),
  "decode response": () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/middleware/decode response.ts"),
  "event-details": () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/middleware/event-details.ts"),
  "merchant-detail": () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/middleware/merchant-detail.ts"),
  "order-status": () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/middleware/order-status.ts"),
  "participants-details": () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/middleware/participants-details.ts"),
  "payment-status": () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/middleware/payment-status.ts"),
  tickets: () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/middleware/tickets.ts"),
  "utils-data": () => import("/Users/szejinggo/Documents/Projects/wemotoo-webapp/middleware/utils-data.ts")
}