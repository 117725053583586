import type { StateResp } from '../types/response';
import HttpFactory from '../factory';
import Routes from '../routes.client';

export default class StateModule extends HttpFactory {
	private readonly RESOURCE = Routes.State;

	/**
	 * get available state
	 * @returns StateResp
	 */
	async getStates(): Promise<StateResp> {
		const response = await this.call<StateResp>(
			{
				method: 'GET',
				url: `${this.RESOURCE.GetStates()}`,
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<StateResp>(response);
	}
}
