import { getAnalytics, logEvent, type Analytics } from 'firebase/analytics';
import { initializeApp, type FirebaseApp, type FirebaseOptions } from 'firebase/app';
import { defineStore } from 'pinia';

export const useFirebaseStore = defineStore({
	id: 'firebaseStore',
	state: () => ({
		analytic: undefined as Analytics | undefined,
	}),
	actions: {
		logCustomEvent(eventName: string, eventParams?: Record<string, any>) {
			const dev = import.meta.dev;
			if (this.analytic === null) {
				throw new Error('Analytic instance is null');
			}

			if (dev) {
				console.log('dev mode : log event');
				return;
			}

			try {
				const userStore = useUserStore();
				const { user } = storeToRefs(userStore);
				if (user.value) {
					eventParams = {
						...eventParams,
						user_id: user.value.id,
						user_name: user.value.name,
					};
				}

				logEvent(this.analytic!, eventName, eventParams);
				console.log('log event success');
			} catch (error) {
				console.log('Error logging event:', error);
			}
		},

		initializeAnalytics() {
			// const { $analytics } = useNuxtApp();
			// this.analytic = $analytics as Analytics | undefined;
			const config = useRuntimeConfig();
			const dev = import.meta.dev;

			if (dev) {
				console.log('dev mode : firebase plugin initialized');
				return;
			}
			const firebaseConfig: FirebaseOptions = {
				apiKey: config.public.FB_API_KEY as string,
				authDomain: config.public.FB_AUTH_DOMAIN as string,
				projectId: config.public.FB_PROJECT_ID as string,
				storageBucket: config.public.FB_STORAGE_BUCKET as string,
				messagingSenderId: config.public.FB_MESSAGING_SENDER_ID as string,
				appId: config.public.FB_APP_ID as string,
				measurementId: config.public.FB_MEASUREMENT_ID as string,
			};

			const app: FirebaseApp = initializeApp(firebaseConfig);
			console.log('firebase plugin initialized');
			const analytics = getAnalytics(app);

			this.analytic = analytics;
			if (!this.analytic) {
				throw new Error('Analytic instance is null');
			}
		},
	},
});
