import type { KnowledgeCategoryReq, KnowledgeListingReq, KnowledgeReq } from '../types/request';
import HttpFactory from '../factory';
import Routes from '../routes.client';
import type { KnowledgeCategory, Knowledge } from '../../types';

export default class ArticleModule extends HttpFactory {
	private readonly RESOURCE = Routes.Knowledge;

	/**
	 * get knowledge categories
	 * @returns list of KnowledgeCategory
	 */
	async getKnowledgeCategories(data: KnowledgeCategoryReq): Promise<KnowledgeCategory[]> {
		const response = await this.call<KnowledgeCategory[]>(
			{
				method: 'POST',
				url: `${this.RESOURCE.GetKnowledgeCategories()}`,
				body: data,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<KnowledgeCategory[]>(response);
	}

	/**
	 * get knowledge by category id
	 * @returns list of KnowledgeCategory
	 */
	async getKnowledgeByCategoryId(data: KnowledgeListingReq): Promise<Knowledge[]> {
		const response = await this.call<Knowledge[]>(
			{
				method: 'POST',
				url: `${this.RESOURCE.GetKnowledgesByCategoryId()}`,
				body: data,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<Knowledge[]>(response);
	}

	/**
	 * get knowledge categories
	 * @returns list of KnowledgeCategory
	 */
	async getKnowledgeDetailById(data: KnowledgeReq): Promise<Knowledge> {
		const response = await this.call<Knowledge>(
			{
				method: 'POST',
				url: `${this.RESOURCE.GetKnowledgeDetails()}`,
				body: data,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<Knowledge>(response);
	}
}
