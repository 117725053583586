import revive_payload_client_4sVQNw7RlN from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_HQNyLQlVwt from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import slideovers_X7whXyrIWR from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import chunk_reload_client_UciE0i6zes from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_GFfDXud5Cr from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/plugins/api.ts";
import _0_init_app_9QUQZ6Umxk from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/plugins/0_init_app.ts";
import firebase_client_zXNDa0wxFH from "/Users/szejinggo/Documents/Projects/wemotoo-webapp/plugins/firebase.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_HQNyLQlVwt,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  chunk_reload_client_UciE0i6zes,
  api_GFfDXud5Cr,
  _0_init_app_9QUQZ6Umxk,
  firebase_client_zXNDa0wxFH
]