export const DOWNLOAD_GOOGLE_PLAY: string = 'download_google_play';
export const DOWNLOAD_IOS_APPSTORE: string = 'download_ios_appstore';
export const DOWNLOAD_HUAWEI_APP_GALLERY: string = 'download_huawei_app_gallery';
export const CHECKOUT: string = 'checkout';
export const BEGIN_CHECKOUT: string = 'begin_checkout';
export const APPLY_VOUCHER: string = 'apply_voucher';
export const PURCHASE: string = 'purchase';
export const PURCHASE_ERROR: string = 'purchase_error';
export const PAYMENT_FAIL: string = 'payment_failed';
export const PAYMENT_SUCCESS: string = 'payment_succeed';

export const REGISTER_VIEW: string = 'register_view';
export const REGISTER: string = 'register';
export const REGISTER_ERROR: string = 'register_error';
export const LOGIN_VIEW: string = 'login_view';
export const LOGIN: string = 'login';
export const LOGIN_ERROR: string = 'login_error';
export const REQUEST_OTP: string = 'request_otp';
export const HOME_VIEW: string = 'home_view';
export const PROFILE_VIEW: string = 'profile_view';
export const ERROR_VIEW: string = 'error_view';

export const SELECT_ITEM: string = 'select_item';
export const ADD_TO_CART: string = 'add_to_cart';
export const VIEW_ITEM: string = 'view_item';
export const VIEW_MY_TICKETS: string = 'view_my_tickets';
export const VIEW_MY_EVENTS: string = 'view_my_events';
export const VIEW_PAYMENT_HISTORY: string = 'view_payment_history';
export const VIEW_MY_PROFILE: string = 'view_my_profile';
export const TERM_CONDITIONS: string = 'term_conditions';
export const ABOUT_US: string = 'about_us';
export const PRIVACY_POLICY: string = 'privacy_policy';

export const SEARCH_EVENT: string = 'search_event';

export const SELECT_SHOP: string = 'select_shop';
export const VIEW_SHOP: string = 'view_shop';
export const SELECT_ARTICLE: string = 'select_article';
export const VIEW_ARTICLE: string = 'view_article';
