import type { CountryResp } from '../types/response';
import HttpFactory from '../factory';
import Routes from '../routes.client';

export default class CountryModule extends HttpFactory {
	private readonly RESOURCE = Routes.Country;

	/**
	 * get available countries
	 * @returns CountryResp
	 */
	async getCountries(): Promise<CountryResp> {
		const response = await this.call<CountryResp>(
			{
				method: 'GET',
				url: `${this.RESOURCE.GetCountries()}`,
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<CountryResp>(response);
	}
}
