import type { CheckoutReq, OrderDetailsReq, OrdersReq } from '../types/request';
import type { CheckoutResp } from '../types/response';
import HttpFactory from '../factory';
import Routes from '../routes.client';
import type { Order } from '../../types';

export default class OrderModule extends HttpFactory {
	private readonly RESOURCE = Routes.Order;

	/**
	 * get order histories
	 * @returns list of Order history
	 */
	async getOrderHistories(data: OrdersReq): Promise<Order[]> {
		const response = await this.call<Order[]>(
			{
				method: 'POST',
				url: `${this.RESOURCE.GetOrders()}`,
				body: data,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<Order[]>(response);
	}

	/**
	 * get order detail
	 * @returns order detail
	 */
	async getOrderDetails(data: OrderDetailsReq): Promise<Order> {
		const response = await this.call<Order>(
			{
				method: 'POST',
				url: `${this.RESOURCE.GetOrderDetail()}`,
				body: data,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<Order>(response);
	}

	/**
	 * checkout order
	 * @returns checkout response
	 */
	async checkout(data: CheckoutReq): Promise<CheckoutResp> {
		const response = await this.call<CheckoutResp>(
			{
				method: 'POST',
				url: `${this.RESOURCE.Checkout()}`,
				body: data,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<CheckoutResp>(response);
	}
}
