import { defineStore } from 'pinia';
import type { EventsReq } from '../repository/types/request/events.request';
import type { EventDetailsReq } from '../repository/types/request';
import type { IApiInstance } from '../types/plugins';
import type { WemotooEvent } from '../types';

export const useEventStore = defineStore({
	id: 'eventStore',
	state: () => {
		return {
			loading: false as boolean,
			events: [] as WemotooEvent[],
			eventsByState: [] as WemotooEvent[],
			eventDetails: <WemotooEvent>{},
			stateId: undefined as number | undefined,
			keyword: undefined as string | undefined,
		};
	},

	getters: {
		isLoading(): boolean {
			return this.loading;
		},
		showMoreEventsButton(): boolean {
			return this.eventsByState.length % 6 === 0;
		},
		getStateName(): string {
			let stateName = '';
			const utilsState = useUtilsDataStore();
			const { states } = storeToRefs(utilsState);

			if (this.stateId == null) return 'All States';

			const state = states.value.find((state) => state.id === this.stateId);
			if (state != null) {
				stateName = state.name;
			}
			return stateName;
		},
	},
	actions: {
		async updateStateId(id: number | undefined) {
			this.stateId = id;
			await this.getEventByStateId(0);
		},
		async updateKeyword(keyword: string | undefined) {
			this.keyword = keyword;
			await this.getEventByStateId(0);
		},
		async getEvents(skip: number = 0) {
			const userStore = useUserStore();
			this.loading = true;
			try {
				const request: EventsReq = {
					skip: skip.toString(),
					take: '6'.toString(),
					lat: userStore.location?.latitude.toString() ?? '0',
					lng: userStore.location?.longitude.toString() ?? '0',
				};

				const $api = useNuxtApp().$api as IApiInstance;
				const response = await $api.event.getEvents(request);

				if (skip == 0) {
					this.events = response || [];
				} else {
					this.events.push(...(response || []));
				}

				if (this.stateId == null) {
					this.eventsByState = response || [];
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},
		async getEventById(id: number) {
			const userStore = useUserStore();

			this.loading = true;
			try {
				const request: EventDetailsReq = {
					id: id.toString(),
					lat: userStore.location?.latitude.toString() ?? '0',
					lng: userStore.location?.longitude.toString() ?? '0',
					with_gallery: '1',
					with_sponsor: '1',
				};

				const $api = useNuxtApp().$api as IApiInstance;
				const response = await $api.event.getEventDetailsById(request);

				this.eventDetails = response;
				return true;
			} catch (error) {
				console.error(error);
				return false;
			} finally {
				this.loading = false;
			}
		},
		async getEventByStateId(skip: number = 0) {
			const userStore = useUserStore();

			this.loading = true;

			try {
				const request: EventsReq = {
					skip: skip.toString(),
					take: '6'.toString(),
					lat: userStore.location?.latitude.toString() ?? '0',
					lng: userStore.location?.longitude.toString() ?? '0',
				};

				if (this.stateId) {
					request['state_id'] = this.stateId.toString();
				}
				if (this.keyword) {
					request['search'] = this.keyword;
				}

				const $api = useNuxtApp().$api as IApiInstance;
				const response = await $api.event.getEvents(request);

				if (skip == 0) {
					this.eventsByState = response || [];
				} else {
					this.eventsByState.push(...(response || []));
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},
	},
});
