import type { EventDetailsReq, TicketDetailsReq } from '../types/request';
import type { EventsReq } from '../types/request/events.request';
import type { EventPrefillDataResp } from '../types/response';
import HttpFactory from '../factory';
import Routes from '../routes.client';
import type { WemotooEvent, Ticket } from '../../types';

export default class EventModule extends HttpFactory {
	private readonly RESOURCE = Routes.Event;

	/**
	 * get events
	 * @returns list of WemotooEvent
	 */
	async getEvents(data: EventsReq): Promise<WemotooEvent[]> {
		const response = await this.call<WemotooEvent[]>(
			{
				method: 'POST',
				url: `${this.RESOURCE.GetEvents()}`,
				body: data,
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<WemotooEvent[]>(response);
	}

	/**
	 * get user events
	 * @returns list of WemotooEvent
	 */
	async getUserEvents(data: EventsReq): Promise<WemotooEvent[]> {
		const response = await this.call<WemotooEvent[]>(
			{
				method: 'POST',
				url: `${this.RESOURCE.GetEvents()}`,
				body: data,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<WemotooEvent[]>(response);
	}

	/**
	 * get event detail By Id
	 * @returns event detail
	 */
	async getEventDetailsById(data: EventDetailsReq): Promise<WemotooEvent> {
		const response = await this.call<WemotooEvent>(
			{
				method: 'POST',
				url: `${this.RESOURCE.GetEventDetails()}`,
				body: data,
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<WemotooEvent>(response);
	}

	/**
	 * get event tickets By event Id
	 * @returns list of tickets
	 */
	async getTicketDetailsByEventId(data: TicketDetailsReq): Promise<Ticket[]> {
		const response = await this.call<Ticket[]>(
			{
				method: 'POST',
				url: `${this.RESOURCE.GetEventTickets()}`,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
				body: data,
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<Ticket[]>(response);
	}

	/**
	 * get event prefill data
	 * @returns EventPrefillDataResp
	 */
	async getEventPrefill(): Promise<EventPrefillDataResp> {
		const response = await this.call<EventPrefillDataResp>(
			{
				method: 'GET',
				url: `${this.RESOURCE.GetPrefillDetail()}`,
				headers: {
					Authorization: `Bearer ${HttpFactory.token}`,
				},
			},
			(error) => {
				throw error.data?.data.message;
			},
		);

		return this.validateResponse<EventPrefillDataResp>(response);
	}
}
